import { api } from "@/api";
import router from "@/router";
import { getLocalToken, removeLocalToken, saveLocalToken } from "@/utils";
import { AxiosError } from "axios";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import { apiUrl } from "@/env";
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetBillingAddress,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetServiceAddress,
  commitSetToken,
  commitSetUserProfile,
} from "./mutations";
import { AppNotification, MainState } from "./state";
import _ from "lodash";

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string }
  ) {
    try {
      const response = await api.logInGetToken(
        payload.username,
        payload.password
      );
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteLoggedIn(context);
        // commitAddNotification(context, { content: 'Logged in', color: 'success' });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionTokenLogIn(context: MainContext, payload: { token: string }) {
    try {
      const response = await api.tokenLogin(payload.token);
      const token = response.data.access_token;
      const link = response.data.link;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        if (link) {
          location.href = `${apiUrl}${link}`;
        } else {
          await dispatchRouteLoggedIn(context);
        }
        // commitAddNotification(context, { content: 'Logged in', color: 'success' });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionGetUserProfile(context: MainContext) {
    try {
      const response = await api.getMe(context.state.token);
      if (response.data) {
        commitSetUserProfile(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUserProfile(context: MainContext, payload) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateMe(context.state.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetUserProfile(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Profile successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    let token = context.state.token;
    //if (!context.state.isLoggedIn) {
    if (!token) {
      const localToken = getLocalToken();
      if (localToken) {
        commitSetToken(context, localToken);
        token = localToken;
      }
    }
    //}
    if (token) {
      try {
        const response = await api.getMe(token);
        commitSetLoggedIn(context, true);
        commitSetUserProfile(context, response.data);
        return response.data;
      } catch (error) {
        await dispatchRemoveLogIn(context);
      }
    } else {
      await dispatchRemoveLogIn(context);
    }
    //}
  },

  async actionGetBillingAddress(context: MainContext, callback) {
    const token = context.state.token;
    if (token) {
      try {
        const response = await api.getBillingAddress(token);
        if (callback) {
          callback(response.data);
        }
        commitSetBillingAddress(context, response.data);
      } catch (error) {
        console.log(error);
      }
    }
  },

  async actionGetReceipts(context: MainContext, callback) {
    const token = context.state.token;
    if (token) {
      try {
        const response = await api.getReceipts(token);
        if (callback) {
          callback(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },

  async actionGetPromos(
    context: MainContext,
    payload: {
      code: string;
      callback: any;
    }
  ) {
    const token = context.state.token;
    if (token) {
      try {
        const response = await api.getPromos(token, { code: payload.code });
        if (payload.callback) {
          payload.callback(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  async actionGetServices(context: MainContext, callback) {
    const token = context.state.token;

    if (token) {
      try {
        const response = await api.getServices(token);
        if (callback) {
          callback(_.get(response, "data.data", {}));
        }
      } catch (error) {
        console.log(error);
      }
    }
  },

  async actionGetServiceAddress(context: MainContext, callback) {
    const token = context.state.token;

    if (token) {
      try {
        const response = await api.getServiceAddress(token);
        if (callback) {
          callback(response.data);
        }
        commitSetServiceAddress(context, response.data);
      } catch (error) {
        console.log(error);
      }
    }
  },
  async actionGetServiceBuilding(
    context: MainContext,
    callback = (data) => data
  ) {
    const token = context.state.token;

    if (token) {
      try {
        const response = (
          await Promise.all([
            api.getServiceBuilding(token),
            await new Promise((resolve, reject) =>
              setTimeout(() => resolve(), 500)
            ),
          ])
        )[0];
        if (callback) {
          callback(response.data);
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken();
    commitSetToken(context, "");
    commitSetLoggedIn(context, false);
  },
  async actionLogOut(context: MainContext) {
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
    // commitAddNotification(context, { content: 'Logged out', color: 'success' });
  },
  actionRouteLogOut(context: MainContext) {
    if (router.currentRoute.path !== "/login") {
      router.push("/login");
    }
  },
  async actionCheckApiError(context: MainContext, payload: AxiosError) {
    if (payload.response!.status === 401) {
      await dispatchLogOut(context);
    }
  },
  actionRouteLoggedIn(context: MainContext) {
    if (
      router.currentRoute.path === "/login" ||
      router.currentRoute.path === "/" ||
      router.currentRoute.path === "/logout"
    ) {
      router.push("/main");
    }
  },
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number }
  ) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingNotification = {
      content: "Sending password recovery email",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      commitRemoveNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.passwordRecovery(payload.username),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitAddNotification(context, {
        content: "Password recovery email sent",
        color: "success",
      });
      commitRemoveNotification(context, loadingNotification);
      router.push("/login");
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: "error",
        content: "Incorrect username",
      });
    }
  },
  async signup(context: MainContext, payload) {
    const loadingNotification = {
      content: "Sending Signup Information...",
      showProgress: true,
    };
    try {
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.signup(payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      const eligible = _.get(response, "data.eligible", false);
      commitRemoveNotification(context, loadingNotification);
      //commitAddNotification(context, { content: 'Signup Successful', color: 'success' });
      await dispatchLogIn(context, {
        username: payload.email,
        password: payload.password,
      });

      if (eligible) {
        location.href = `${apiUrl}/id_verify`;
      } else {
        location.href = `${apiUrl}/contact`;
      }

      if (payload.checkAddress) {
        return {
          msg: "Address Eligibility",
          eligible: eligible,
        };
      }
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: "error",
        content: "Email: Please check the value",
      });
      throw "error";
    }
  },
  async signupVerify(context: MainContext, payload) {
    const loadingNotification = {
      content: "Sending Signup Information...",
      showProgress: true,
    };
    try {
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.signup_verify(context.state.token, payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      const eligible = _.get(response, "data.eligible", false);
      commitRemoveNotification(context, loadingNotification);
      //commitAddNotification(context, { content: 'Signup Successful', color: 'success' });
      if (eligible) {
        location.href = `${apiUrl}/id_verify`;
      } else {
        location.href = `${apiUrl}/contact`;
      }

      if (payload.checkAddress) {
        return {
          msg: "Address Eligibility",
          eligible: eligible,
        };
      }
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: "error",
        content: "Email: Please check the value",
      });
      throw "error";
    }
  },
  async selectPlan(context: MainContext, payload) {
    try {
      const loadingNotification = {
        content: "Sending Request...",
        showProgress: true,
      };
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.selectPlan(context.state.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async upgradePlan(context: MainContext, payload) {
    try {
      const loadingNotification = {
        content: "Sending Request...",
        showProgress: true,
      };
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.upgradePlan(context.state.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async postFreeBill(context: MainContext, payload) {
    try {
      const loadingNotification = {
        content: "Sending Request...",
        showProgress: true,
      };
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.postFreeBill(context.state.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async postFreeManualBill(context: MainContext, payload) {
    try {
      const loadingNotification = {
        content: "Sending Request...",
        showProgress: true,
      };
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.postFreeManualBill(context.state.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async splynxToken(context: MainContext, payload) {
    try {
      const loadingNotification = {
        content: "Sending Request...",
        showProgress: true,
      };
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.getToken(context.state.token),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async checkAddress(context: MainContext, payload) {
    const loadingNotification = {
      content: "Checking Address...",
      showProgress: true,
    };
    // commitAddNotification(context, loadingNotification);
    const response = (
      await Promise.all([
        api.checkAddress(context.state.token, payload),
        await new Promise((resolve, reject) =>
          setTimeout(() => resolve(), 500)
        ),
      ])
    )[0];
    const eligible = _.get(response, "data.eligible", false);

    if (eligible) {
      router.push({
        name: "id_verify",
      });
    } else {
      router.push({
        name: "contact",
        params: {
          progress: "2",
        },
      });
    }
    return response;
  },
  async uploadDocs(context: MainContext, payload) {
    try {
      const loadingNotification = {
        content: "Uploading Documents...",
        showProgress: true,
      };
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.uploadDocs(context.state.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];

      const eligible = _.get(response, "data.eligible", false);
      if (eligible) {
        router.push({
          name: "contact_review",
        });
      } else {
        router.push({
          name: "contact",
          params: {},
        });
      }
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string }
  ) {
    const loadingNotification = {
      content: "Resetting password",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.resetPassword(context.state.token, payload.password),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Password successfully reset",
        color: "success",
      });
      await dispatchRouteLoggedIn(context);
      // await dispatchLogOut(context);
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: "error",
        content: "Error resetting password",
      });
    }
  },

  async actionGetBilling(context: MainContext, payload) {
    const token = context.state.token;

    if (token) {
      try {
        const response = (
          await Promise.all([
            api.postGetMyBilling(token, payload),
            await new Promise((resolve, reject) =>
              setTimeout(() => resolve(), 500)
            ),
          ])
        )[0];
        return response.data;
      } catch (error) {
        console.log(error);
      }
    }
  },
  async payNow(context: MainContext, payload) {
    try {
      const loadingNotification = {
        content: "Sending Request...",
        showProgress: true,
      };
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.payNowMultiple(context.state.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGoogleLogin(context: MainContext, payload) {
    try {
      const response = await api.postGoogleAuth(payload);
      const token = _.get(response, "data.data.access_token", "");
      const data = _.get(response, "data.data.result", "");
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        const status = _.get(data, "progress_status_code", "12");
        const phone = _.get(data, "phone", "");

        if (status === "12" || !phone) {
          location.href = `${apiUrl}/apply`;
        } else {
          location.href = `${apiUrl}/`;
        }

        //commitAddNotification(context, { content: 'Logged in', color: 'success' });
      } else {
        //await dispatchLogOut(context);
      }
    } catch (err) {
      //commitSetLogInError(context, true);
      //await dispatchLogOut(context);
    }
  },

  async actionFacebookLogin(context: MainContext, payload) {
    try {
      const response = await api.postFacebookAuth(payload);
      const token = _.get(response, "data.data.access_token", "");
      const data = _.get(response, "data.data.result", "");
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        const status = _.get(data, "progress_status_code", "12");
        const phone = _.get(data, "phone", "");
        if (status === "12" || !phone) {
          location.href = `${apiUrl}/apply`;
        } else {
          location.href = `${apiUrl}/`;
        }

        //commitAddNotification(context, { content: 'Logged in', color: 'success' });
      }
    } catch (err) {
      throw "error";
    }
  },

  async actionAppleLogin(context: MainContext, payload) {
    try {
      const response = await api.postAppleAuth(payload);
      const token = _.get(response, "data.data.access_token", "");
      const data = _.get(response, "data.data.result", "");
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        const status = _.get(data, "progress_status_code", "12");
        const phone = _.get(data, "phone", "");
        if (status === "12" || !phone) {
          location.href = `${apiUrl}/apply`;
        } else {
          location.href = `${apiUrl}/`;
        }
      }
    } catch (err) {
      throw "error";
    }
  },
  async actionAutoRenew(context: MainContext, payload) {
    const msg = payload.is_auto_renew
      ? "Turned On Auto Renew Contract"
      : "Turned Off Auto Renew Contract";
    const loadingNotification = { content: msg, showProgress: false };
    commitAddNotification(context, loadingNotification);
    const response = await api.auto_renew(context.state.token, payload);
  },
  async actionSendRefer(context: MainContext, payload) {
    const response = await api.sendReferral(context.state.token, payload);
    const msg = "Sent Referrals";
    const loadingNotification = { content: msg, showProgress: false };
    commitAddNotification(context, loadingNotification);
  },
  async sendReferralReminder(context: MainContext, payload) {
    try {
      const loadingNotification = {
        content: "Sending Reminder...",
        showProgress: true,
      };
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.sendReferralReminderFromUser(context.state.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async getReferrals(context: MainContext) {
    try {
      const response = (
        await Promise.all([
          api.getReferrals(context.state.token),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];

      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async renewalSetting(context: MainContext, payload) {
    try {
      const loadingNotification = {
        content: "Updating Renwal Contract",
        showProgress: true,
      };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.renewalSetting(context.state.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      const loadingNotification2 = {
        content: "Updated Renwal Contract",
        showProgress: true,
      };
      commitAddNotification(context, loadingNotification2);

      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async set20(context: MainContext, payload) {
    try {
      const response = (
        await Promise.all([
          api.set20(context.state.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];

      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async searchBuilding(context: MainContext, payload) {
    try {
      const response = (
        await Promise.all([
          api.searchBuilding(payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];

      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

};

const { dispatch } = getStoreAccessors<MainState | any, State>("");

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(
  actions.actionUpdateUserProfile
);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchSignup = dispatch(actions.signup);
export const dispatchSignupVerify = dispatch(actions.signupVerify);
export const dispatchSelectPlan = dispatch(actions.selectPlan);
export const dispatchGetServiceAddress = dispatch(
  actions.actionGetServiceAddress
);
export const dispatchGetBillingAddress = dispatch(
  actions.actionGetBillingAddress
);
export const dispatchUploadDocs = dispatch(actions.uploadDocs);
export const dispatchCheckAddress = dispatch(actions.checkAddress);
export const dispatchGetServices = dispatch(actions.actionGetServices);
export const dispatchUserTokenLogin = dispatch(actions.actionTokenLogIn);
export const dispatchSplynxToken = dispatch(actions.splynxToken);
export const dispatchGetReceipts = dispatch(actions.actionGetReceipts);
export const dispatchGetPromos = dispatch(actions.actionGetPromos);
export const dispatchPostFreeBill = dispatch(actions.postFreeBill);
export const dispatchPostFreeManualBill = dispatch(actions.postFreeManualBill);
export const dispatchGetServiceBuilding = dispatch(
  actions.actionGetServiceBuilding
);
export const dispatchGetBilling = dispatch(actions.actionGetBilling);
export const dispatchPayNow = dispatch(actions.payNow);
export const dispatchGoogleLogin = dispatch(actions.actionGoogleLogin);
export const dispatchFacebookLogin = dispatch(actions.actionFacebookLogin);
export const dispatchAppleLogin = dispatch(actions.actionAppleLogin);
export const dispatchAutoRenew = dispatch(actions.actionAutoRenew);
export const dispatchSendRefer = dispatch(actions.actionSendRefer);
export const dispatchUpgradePlan = dispatch(actions.upgradePlan);
export const dispatchSendReferralReminder = dispatch(
  actions.sendReferralReminder
);
export const dispatchRenewalSetting = dispatch(actions.renewalSetting);
export const dispatchSet20 = dispatch(actions.set20);
export const dispatchSearchBuilding = dispatch(actions.searchBuilding);
