import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IUserProfileCreate, IUserProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetUsers, commitSetUser } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

type MainContext = ActionContext<AdminState, State>;

export const actions = {
    async actionGetUsers(context: MainContext) {
        try {
            const loadingNotification = { content: `Loading Users Data...`, showProgress: true };
            commitAddNotification(context, loadingNotification);

            const response = await api.getUsers(context.rootState.main.token);
            if (response) {
                commitSetUsers(context, response.data);
            }
            commitAddNotification(context, { content: `Loaded Users Data`, color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUser(context: MainContext, payload: { id: number }) {
        try {
            const loadingNotification = { content: `Loading User ID ${payload.id}`, showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.getUser(context.rootState.main.token, payload.id);
            commitAddNotification(context, { content: `Loaded User ID ${payload.id}`, color: 'success' });
            if (response) {
                return response.data
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async salesReport(context: MainContext, payload: {}) {
        try {
            const loadingNotification = { content: `Loading Sales Report`, showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.salesReport(context.rootState.main.token);
            commitAddNotification(context, { content: `Loaded Sales Report`, color: 'success' });
            if (response) {
                return response.data
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateUser(context: MainContext, payload: { id: number, user: IUserProfileUpdate }) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateUser(context.rootState.main.token, payload.id, payload.user),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'User successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createUser(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'User successfully created', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionSendToPayment(context: MainContext, userId) {
        try {
            const loadingNotification = { content: 'Sending Payment Link', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.sendUserPaymentSelection(context.rootState.main.token, userId),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Send Payment Notification Successfully Sent', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionSendToIDUpload(context: MainContext, userId) {
        try {
            const loadingNotification = { content: 'Sending ID Upload Link', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.sendUserIDUpload(context.rootState.main.token, userId),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'ID Upload Link Successfully Sent', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionNotFeasible(context: MainContext, userId) {
        try {
            const loadingNotification = { content: 'Marking as not Feasible', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.sendNotFeasible(context.rootState.main.token, userId),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Marked as Not Feasible', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionNotActivated(context: MainContext, userId) {
        try {
            const loadingNotification = { content: 'Marking as not Activated', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.sendNotActivated(context.rootState.main.token, userId),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Marked as Not Activated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteDoc(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'Deleting Document...', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.sendDeleteDoc(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Deleted Documnet', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionScheduleConfirm(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'Confirming Schedule', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.sendScheduleConfirm(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Schedule Confirmed', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionActivationConfirm(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'Confirming Activation', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.sendActivationConfirm(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Activation Confirmed', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionResetProgress(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'Resetting Progress', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.sendResetProgress(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Progress Reset Done', color: 'success' });

        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionRecurringBill(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'Manual Billing Customer...', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.recurringBill(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Manual Billing Done', color: 'success' });
            return response.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionRefundBillingItem(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'Refunding Customer...', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.refundBill(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Refund Billing Done', color: 'success' });
            return response.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionRefundReBillingItem(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'Refunding Customer...', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.refundRebill(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Refund Billing Done', color: 'success' });
            return response.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionPaymentReminder(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'Sending Payment Reminder to Customer...', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.paymentReminder(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Sending Payment Reminder to Customer Done', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionGetQB(context: MainContext, callback) {
        try {
            const loadingNotification = { content: 'Getting QB...', showProgress: true };
            commitAddNotification(context, loadingNotification);
            api.getQB(context.rootState.main.token).then((response) => {
                callback(response.data)
            })

            commitAddNotification(context, { content: 'Getting QB Done', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionPostQB(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'Posting QB Auth...', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                await api.postQB(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response;


            commitAddNotification(context, { content: 'Posting QB Auth', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async addRebate(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'Adding Rebate...', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.addRebate(context.rootState.main.token, payload)

            commitAddNotification(context, { content: 'Added Rebate', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async postDeleteUser(context: MainContext, payload) {
        const loadingNotification = { content: 'Deleting User', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.postDeleteUser(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Deleted User', color: 'success' });
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Error resetting password' });
        }
    },
    async postCreateBuildingAdmin(context: MainContext, payload: {
        email: string,
        phone: string,
        firstName: string,
        lastName: string,
        unit: string,
        address: string,
        addressData: string,
        placeData: string,
        placeId: string,
    }) {
        const loadingNotification = { content: 'Sending Signup Information...', showProgress: true };
        try {
            // commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.postCreateBuildingAdmin(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Signup Successful', color: 'success' });
            return response
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Signup Error' });
        }
    },

    async actionGetRebillSummary(context: MainContext, payload) {
        const token = context.rootState.main.token;

        if (token) {
            try {
                const response = (await Promise.all([
                    api.postGetRebillSummary(token, payload),
                    await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
                ]))[0];
                return response.data;
            } catch (error) {
                console.log(error)
            }
        }
    },

    async actionRecurBill(context: MainContext, payload) {
        const token = context.rootState.main.token;

        if (token) {
            try {
                const response = (await Promise.all([
                    api.postRecurBill(token, payload),
                    await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
                ]))[0];
                return response.data;
            } catch (error) {
                console.log(error)
            }
        }
    },
    async uploadAdminDocs(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'Uploading Documents...', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.uploadAdminDocs(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];

            return response;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionSendReferReminder(context: MainContext, payload) {
        const response = await api.sendReferralReminder(context.rootState.main.token, payload)
        const msg = 'Sent Referral Reminder'
        const loadingNotification = { content: msg, showProgress: false };
        commitAddNotification(context, loadingNotification);
    },
    async actionSendReferReminderToSender(context: MainContext, payload) {
        const response = await api.sendReferralReminderToSender(context.rootState.main.token, payload)
        const msg = 'Sent Referral Reminder to Sender'
        const loadingNotification = { content: msg, showProgress: false };
        commitAddNotification(context, loadingNotification);
    },
    async editAddress(context: MainContext, payload) {
        const loadingNotification = { content: 'Updating Address...', showProgress: true };
        // commitAddNotification(context, loadingNotification);
        const response = (await Promise.all([
            api.editAddress(context.rootState.main.token, payload),
            await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];

        return response;

    },
    async manualPayToggle(context: MainContext, payload) {
        const loadingNotification = { content: 'Updating Auto Payment...', showProgress: true };
        // commitAddNotification(context, loadingNotification);
        const response = (await Promise.all([
            api.manualPayToggle(context.rootState.main.token, payload),
            await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];

        return response;

    },
    async marketSegment(context: MainContext, payload) {
        const loadingNotification = { content: 'Updating Market Segment...', showProgress: true };
        // commitAddNotification(context, loadingNotification);
        const response = (await Promise.all([
            api.marketSegment(context.rootState.main.token, payload),
            await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];

        return response;

    },
    async buildings(context: MainContext, payload) {
        const loadingNotification = { content: 'Loading Buildings...', showProgress: true };
        // commitAddNotification(context, loadingNotification);
        const response = (await Promise.all([
            api.buildings(context.rootState.main.token, payload),
            await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];

        return response;

    },
    async all_receipts(context: MainContext, payload) {
        const loadingNotification = { content: 'Loading Receipts...', showProgress: true };
        // commitAddNotification(context, loadingNotification);
        const response = (await Promise.all([
            api.all_receipts(context.rootState.main.token, payload),
            await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];

        return response;

    },
    async inactivate(context: MainContext, user_id) {
        const loadingNotification = { content: 'Inactivating...', showProgress: true };
        // commitAddNotification(context, loadingNotification);
        const response = (await Promise.all([
            api.inactivate(context.rootState.main.token, user_id),
            await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];
        console.log(response);
        alert(response.data.msg);
        return response;

    },
    async MARCharts(context: MainContext) {
        const loadingNotification = { content: 'Creating Charts...', showProgress: true };
        // commitAddNotification(context, loadingNotification);
        const response = (await Promise.all([
            api.MARCharts(context.rootState.main.token),
            await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];
        console.log(response);
        //alert(response.data.msg);
        return response;

    },
    async promoCosts(context: MainContext) {
        const loadingNotification = { content: 'Promo Costs...', showProgress: true };
        // commitAddNotification(context, loadingNotification);
        const response = (await Promise.all([
            api.promo_costs(context.rootState.main.token),
            await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];
        console.log(response);
        //alert(response.data.msg);
        return response;

    },
    async botlogReport(context: MainContext, payload) {
        try {
            const response = (
                await Promise.all([
                    api.botlog_report(context.rootState.main.token, payload),
                    await new Promise((resolve, reject) =>
                        setTimeout(() => resolve(), 500)
                    ),
                ])
            )[0];

            return response;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchGetUser = dispatch(actions.actionGetUser);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchSendToPayment = dispatch(actions.actionSendToPayment);
export const dispatchSendToIDUpload = dispatch(actions.actionSendToIDUpload)
export const dispatchNotFeasible = dispatch(actions.actionNotFeasible)
export const dispatchScheduleConfirm = dispatch(actions.actionScheduleConfirm)
export const dispatchActivationConfirm = dispatch(actions.actionActivationConfirm)
export const dispatchResetProgress = dispatch(actions.actionResetProgress)
export const dispatchNotActivated = dispatch(actions.actionNotActivated)
export const dispatchDeleteDoc = dispatch(actions.actionDeleteDoc)
export const dispatchRecurringBill = dispatch(actions.actionRecurringBill)
export const dispatchRefundBillingItem = dispatch(actions.actionRefundBillingItem)
export const dispatchRefundReBillingItem = dispatch(actions.actionRefundReBillingItem)

export const dispatchPaymentReminder = dispatch(actions.actionPaymentReminder)

export const dispatchGetQB = dispatch(actions.actionGetQB)
export const dispatchPostQB = dispatch(actions.actionPostQB)

export const dispatchAddRebate = dispatch(actions.addRebate)
export const dispatchPostDeleteUser = dispatch(actions.postDeleteUser);
export const dispatchPostCreateBuildingAdmin = dispatch(actions.postCreateBuildingAdmin)

export const dispatchGetRebillSummary = dispatch(actions.actionGetRebillSummary)

export const dispatchRecurBill = dispatch(actions.actionRecurBill)
export const dispatchUploadAdminDocs = dispatch(actions.uploadAdminDocs)

export const dispatchSendReferReminder = dispatch(actions.actionSendReferReminder)
export const dispatchSendReferReminderToSender = dispatch(actions.actionSendReferReminderToSender)
export const dispatchEditAddress = dispatch(actions.editAddress)
export const dispatchManualPayToggle = dispatch(actions.manualPayToggle)
export const dispatchSalesReport = dispatch(actions.salesReport)
export const dispatchMarketSegment = dispatch(actions.marketSegment)
export const dispatchBuildings = dispatch(actions.buildings)
export const dispatchAllReceipts = dispatch(actions.all_receipts)
export const dispatchInactivate = dispatch(actions.inactivate)
export const dispatchMARCharts = dispatch(actions.MARCharts)
export const dispatchPromoCosts = dispatch(actions.promoCosts)
export const dispatchBotlogReport = dispatch(actions.botlogReport);
