






























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
  items = [
    {
      title: "Home",
      link: "https://www.horizonfiber.ph/",
    },
    {
      title: "Pricing",
      link: "https://www.horizonfiber.ph/pricing/",
    },
    {
      title: "About Us",
      link: "https://www.horizonfiber.ph/about-us/",
    },
    {
      title: "Contact Us",
      link: "https://www.horizonfiber.ph/contact-us/",
    },
    {
      title: "FAQ",
      link: "https://www.horizonfiber.ph/faq/",
    },
  ];

  redirectUser(link) {
    window.location.href = link;
  }
}
