import axios from "axios";
import { apiUrl } from "@/env";
import {
  IUserProfile,
  IUserAddress,
  IUserProfileUpdate,
  IUserProfileCreate,
} from "./interfaces";

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async tokenLogin(token: string) {
    return axios.post(`${apiUrl}/api/v1/login/token-login/`, {
      token: token,
    });
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      authHeaders(token)
    );
  },
  async MARCharts(token: string) {
    return axios.get(`${apiUrl}/api/v1/charts/mar_report/`, authHeaders(token));
  },
  async getServices(token: string) {
    return axios.get(`${apiUrl}/api/v1/services/`, authHeaders(token));
  },
  async getBillingAddress(token: string) {
    return axios.get<IUserAddress>(
      `${apiUrl}/api/v1/users/my-billing-address`,
      authHeaders(token)
    );
  },
  async getServiceAddress(token: string) {
    return axios.get<IUserAddress>(
      `${apiUrl}/api/v1/users/my-service-address`,
      authHeaders(token)
    );
  },
  async getServiceBuilding(token: string) {
    return axios.get<IUserAddress>(
      `${apiUrl}/api/v1/users/my-service-building`,
      authHeaders(token)
    );
  },
  async searchBuilding(data: string) {
    return axios.post(`${apiUrl}/api/v1/building_search`, {
      building: data,
    });
  },
  async getToken(token: string) {
    return axios.post(`${apiUrl}/api/v1/get_token/`, {}, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token)
    );
  },
  async sendUserPaymentSelection(token: string, userId: number) {
    const data = {
      user_id: userId,
      random: Math.random(),
    };
    return axios.post(
      `${apiUrl}/api/v1/users/send_to_payment/`,
      data,
      authHeaders(token)
    );
  },
  async sendUserIDUpload(token: string, userId: number) {
    const data = {
      user_id: userId,
      random: Math.random(),
    };
    return axios.post(
      `${apiUrl}/api/v1/users/send_to_id_upload/`,
      data,
      authHeaders(token)
    );
  },
  async sendScheduleConfirm(token: string, payload) {
    const data = payload;
    return axios.post(
      `${apiUrl}/api/v1/users/send_schedule_confirm/`,
      data,
      authHeaders(token)
    );
  },
  async sendActivationConfirm(token: string, payload) {
    const data = payload;
    return axios.post(
      `${apiUrl}/api/v1/users/send_activation_confirm/`,
      data,
      authHeaders(token)
    );
  },
  async sendNotFeasible(token: string, userId) {
    const data = {
      user_id: userId,
      random: Math.random(),
    };
    return axios.post(
      `${apiUrl}/api/v1/users/send_not_feasible/`,
      data,
      authHeaders(token)
    );
  },
  async sendNotActivated(token: string, userId) {
    const data = {
      user_id: userId,
      random: Math.random(),
    };
    return axios.post(
      `${apiUrl}/api/v1/users/send_not_activated/`,
      data,
      authHeaders(token)
    );
  },
  async sendDeleteDoc(token: string, payload) {
    const data = {
      user_id: payload.userId,
      doc_id: payload.docId,
      random: Math.random(),
    };
    return axios.post(
      `${apiUrl}/api/v1/users/delete_doc/`,
      data,
      authHeaders(token)
    );
  },
  async set20(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/set_to_20/`,
      payload,
      authHeaders(token)
    );
  },
  async sendResetProgress(token: string, userId) {
    const data = {
      user_id: userId,
      random: Math.random(),
    };
    return axios.post(
      `${apiUrl}/api/v1/users/send_reset_progress/`,
      data,
      authHeaders(token)
    );
  },
  async getUser(token: string, userId: number) {
    return axios.get(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(
      `${apiUrl}/api/v1/users/`,
      authHeaders(token)
    );
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(
      `${apiUrl}/api/v1/users/${userId}`,
      data,
      authHeaders(token)
    );
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async signup(payload) {
    return axios.post(`${apiUrl}/api/v1/signup/`, payload);
  },
  async signup_verify(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/signup-verify/`,
      payload,
      authHeaders(token)
    );
  },
  async auto_renew(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/auto_renew/`,
      payload,
      authHeaders(token)
    );
  },
  async checkAddress(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/check_address/`,
      {
        unit: payload.unit,
        address: payload.address,
        addressData: payload.addressData,
        placeData: payload.placeData,
        placeId: payload.placeId,
      },
      authHeaders(token)
    );
  },
  async selectPlan(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/select_plan/`,
      payload,
      authHeaders(token)
    );
  },
  async upgradePlan(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/upgrade_plan/`,
      payload,
      authHeaders(token)
    );
  },
  async uploadDocs(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/upload_docs/`,
      {
        id_files: payload.id_files,
        bill_files: payload.bill_files,
        is_local: payload.is_local,
      },
      authHeaders(token)
    );
  },
  async uploadAdminDocs(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/upload_docs/`,
      payload,
      authHeaders(token)
    );
  },
  async resetPassword(token: string, password: string) {
    return axios.post(
      `${apiUrl}/api/v1/reset-password/`,
      {
        new_password: password,
        token: "",
      },
      authHeaders(token)
    );
  },
  async refundRebill(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/refund-rebill/`,
      payload,
      authHeaders(token)
    );
  },
  async refundBill(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/refund-bill/`,
      payload,
      authHeaders(token)
    );
  },
  async recurringBill(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/recurring-bill/`,
      payload,
      authHeaders(token)
    );
  },
  async getReceipts(token: string) {
    return axios.post(
      `${apiUrl}/api/v1/users/receipts/`,
      {},
      authHeaders(token)
    );
  },
  async getPromos(token: string, payload) {
    return axios.post(`${apiUrl}/api/v1/promo/`, payload, authHeaders(token));
  },
  async paymentReminder(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/payment-reminder/`,
      payload,
      authHeaders(token)
    );
  },
  async getQB(token: string) {
    return axios.get(`${apiUrl}/api/v1/users/qb/`, authHeaders(token));
  },
  async postQB(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/qb/`,
      payload,
      authHeaders(token)
    );
  },
  async addRebate(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/add-rebate/`,
      payload,
      authHeaders(token)
    );
  },

  async postFreeBill(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/services/bill_free/`,
      payload,
      authHeaders(token)
    );
  },
  async postFreeManualBill(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/services/bill_manual_free/`,
      payload,
      authHeaders(token)
    );
  },
  async postDeleteUser(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/delete-user/`,
      payload,
      authHeaders(token)
    );
  },

  async postGetMyBilling(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/my-billing/`,
      payload,
      authHeaders(token)
    );
  },


  async botlog_report(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/botlog_report/`,
      payload,
      authHeaders(token)
    );
  },

  async postCreateBuildingAdmin(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/create_building_admin`,
      {
        email: payload.email,
        phone: payload.phone,
        firstName: payload.firstName,
        lastName: payload.lastName,
        unit: payload.unit,
        address: payload.address,
        addressData: payload.addressData,
        placeData: payload.placeData,
        placeId: payload.placeId,
      },
      authHeaders(token)
    );
  },
  async payNowMultiple(token: string, payload) {
    return axios.post(`${apiUrl}/api/v1/pay_now/`, payload, authHeaders(token));
  },

  async postGetRebillSummary(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/rebill-summary/`,
      payload,
      authHeaders(token)
    );
  },

  async postRecurBill(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/recur-bill/`,
      payload,
      authHeaders(token)
    );
  },

  async postGoogleAuth(payload) {
    return axios.post(`${apiUrl}/api/v1/oauth/google/`, payload);
  },
  async postFacebookAuth(payload) {
    return axios.post(`${apiUrl}/api/v1/oauth/facebook/`, payload);
  },
  async postAppleAuth(payload) {
    return axios.post(`${apiUrl}/api/v1/oauth/apple/`, payload);
  },
  async sendReferral(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/send-refer/`,
      payload,
      authHeaders(token)
    );
  },
  async sendReferralReminder(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/send-refer-reminder/`,
      payload,
      authHeaders(token)
    );
  },
  async sendReferralReminderToSender(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/send-refer-reminder-to-sender/`,
      payload,
      authHeaders(token)
    );
  },
  async sendReferralReminderFromUser(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/send-refer-reminder-from-user/`,
      payload,
      authHeaders(token)
    );
  },
  async getReferrals(token: string) {
    return axios.get(
      `${apiUrl}/api/v1/users/referred-users/`,
      authHeaders(token)
    );
  },
  async editAddress(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/edit_address/`,
      payload,
      authHeaders(token)
    );
  },
  async manualPayToggle(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/manual_pay/`,
      payload,
      authHeaders(token)
    );
  },
  async salesReport(token: string) {
    return axios.get(
      `${apiUrl}/api/v1/users/sales_report/`,
      authHeaders(token)
    );
  },
  async renewalSetting(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/renewal_setting/`,
      payload,
      authHeaders(token)
    );
  },
  async marketSegment(token: string, payload) {
    return axios.post(
      `${apiUrl}/api/v1/users/market_segment/`,
      payload,
      authHeaders(token)
    );
  },
  async buildings(token: string, payload) {
    return axios.get(`${apiUrl}/api/v1/users/buildings/`, authHeaders(token));
  },
  async all_receipts(token: string, payload) {
    return axios.get(
      `${apiUrl}/api/v1/users/all_receipts/`,
      authHeaders(token)
    );
  },
  async inactivate(token: string, user_id) {
    return axios.post(
      `${apiUrl}/api/v1/users/inactivate/`,
      { user_id },
      authHeaders(token)
    );
  },
  async promo_costs(token: string) {
    return axios.get(`${apiUrl}/api/v1/users/promo_costs/`, authHeaders(token));
  },
};
