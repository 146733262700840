var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"HeaderBGContainer"},[_c('v-card',{attrs:{"align":"center","justify":"center"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2","sm":"12"}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('img',{staticClass:"HeaderLogo",attrs:{"src":"/img/header/logo3.png"}}),_c('v-spacer'),_c('div',{staticClass:"hidden-md-and-up"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":100,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","icon":"","light":""},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-list',[_vm._l((_vm.items),function(n){return _c('v-list-item',{key:n.title,on:{"click":function () {
                      _vm.redirectUser(n.link);
                    }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(n.title)}})],1)}),(!_vm.isLoggedIn())?_c('div',[_c('v-divider'),_vm._l((_vm.loginItems),function(n){return _c('v-list-item',{key:n.title,on:{"click":function () {
                        _vm.redirectUser(n.link);
                      }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(n.title)}})],1)})],2):_vm._e(),(_vm.isLoggedIn())?_c('v-divider'):_vm._e(),_vm._l((_vm.logoutItems),function(n){return (_vm.isLoggedIn())?_c('v-list-item',{key:n.title,on:{"click":function () {
                      _vm.redirectUser(n.link);
                    }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(n.title)}})],1):_vm._e()})],2)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9","sm":"12"}}),_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"12","sm":"12","md":"1"}},[_c('div',{staticStyle:{"margin-top":"7px"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":100,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","icon":"","light":""},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',[_vm._l((_vm.items),function(n){return _c('v-list-item',{key:n.title,on:{"click":function () {
                    _vm.redirectUser(n.link);
                  }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(n.title)}})],1)}),(!_vm.isLoggedIn())?_c('div',[_c('v-divider',{attrs:{"vi-if":"n.separator"}}),_vm._l((_vm.loginItems),function(n){return _c('v-list-item',{key:n.title,on:{"click":function () {
                      _vm.redirectUser(n.link);
                    }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(n.title)}})],1)})],2):_vm._e(),(_vm.isLoggedIn())?_c('v-divider'):_vm._e(),_vm._l((_vm.logoutItems),function(n){return (_vm.isLoggedIn())?_c('v-list-item',{key:n.title,on:{"click":function () {
                    _vm.redirectUser(n.link);
                  }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(n.title)}})],1):_vm._e()})],2)],1)],1)])],1)],1),(_vm.hasProgress())?_c('v-layout',{staticClass:"hidden-md-and-up"},[_c('br'),_c('br'),_c('br'),_c('br'),_c('br')]):_vm._e(),(_vm.hasProgress())?_c('v-layout',{staticClass:"Container",attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"md6":"","sm12":"","xs12":""}},[_c('v-card',[_c('v-img',{attrs:{"src":"/img/header/header_bg_generic.png","height":"20px"}}),_c('v-card-text',{staticClass:"ContentHeader"},[_c('div',[_c('br'),(
                _vm.userProfile &&
                _vm.userProfile.first_name &&
                !_vm.$route.params.firstName
              )?_c('div',{staticClass:"headerWelcome"},[_vm._v(" "+_vm._s(_vm.userProfile.first_name.toUpperCase())+" "+_vm._s(_vm.userProfile.last_name.toUpperCase())+" ")]):_vm._e(),(_vm.userProfile && _vm.userProfile.phone && !_vm.$route.params.phone)?_c('div',{staticClass:"headerInfo"},[_vm._v(" "+_vm._s(_vm.userProfile.phone)+" ")]):_vm._e(),(_vm.userProfile && _vm.userProfile.email && !_vm.$route.params.email)?_c('div',{staticClass:"headerInfo"},[_vm._v(" "+_vm._s(_vm.userProfile.email.toUpperCase())+" ")]):_vm._e(),_c('br'),(_vm.userProfile.service_name)?_c('div',[(_vm.userProfile && _vm.userProfile.service_name)?_c('v-row',[_c('v-col',{staticClass:"ContentHeaderItem",attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.userProfile && _vm.userProfile.service_name)?_c('div',[_vm._v(" "+_vm._s(_vm.userProfile.service_name)+" ")]):_vm._e()])],1):_vm._e()],1):_vm._e(),(_vm.userProfile && _vm.userProfile.splynx_cid)?_c('v-row',{staticStyle:{"margin-top":"10px"}},[_c('v-col',{staticClass:"ContentHeaderItem",attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.userProfile && _vm.userProfile.splynx_cid)?_c('div',[_vm._v(" Customer ID: "+_vm._s(_vm.userProfile.splynx_cid)+" ")]):_vm._e()])],1):_vm._e(),(_vm.userProfile && _vm.userProfile.installation_date)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"ContentHeaderItem",attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.userProfile && _vm.userProfile.installation_date)?_c('div',{attrs:{"a":""}},[_vm._v(" Scheduled Installation Date: "+_vm._s(_vm.formatDate(_vm.userProfile.installation_date))+" ")]):_vm._e()])],1):_vm._e()],1)]),_c('v-img',{attrs:{"src":"/img/header/header_bg_generic.png","height":"20px"}})],1)],1)],1):_vm._e(),(!_vm.$props.no_progress && _vm.hasProgress())?_c('div',[_c('br'),_c('br'),_c('Progress',{attrs:{"progress":_vm.progress}}),_c('br')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }