export const getLocalToken = () => localStorage.getItem('token');

export const saveLocalToken = (token: string) => localStorage.setItem('token', token);

export const removeLocalToken = () => localStorage.removeItem('token');


export const formatAddress = (response) => {
    return `${response.address1 || ""}${(!!response.address1 && (!!response.address2 || !!response.city || !!response.state || !!response.country) && ", ") || ""
        }${response.address2 || ""}${(!!response.address2 && (!!response.city || !!response.state || !!response.country) && ", ") || ""
        }${response.city || ""}${(!!response.city && !!response.state && ", ") || ""
        }${response.state || ""}${(!!response.state && !!response.country && ", ") || ""
        }${response.country || ""}`
}