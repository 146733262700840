


































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import {
  dispatchCheckLoggedIn,
  dispatchSelectPlan,
  dispatchGetServiceAddress,
  dispatchGetBillingAddress,
} from "@/store/main/actions";
import { readServiceAddress, readBillingAddress } from "@/store/main/getters";
import _ from "lodash";

@Component({
  components: {},
  props: {
    progress: {
      type: String,
      default: "0",
    },
  },
})
export default class Progress extends Vue {
  hasProgress() {
    return !!this.$props.progress && parseInt(this.$props.progress) > 1;
  }
}
