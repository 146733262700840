import '@babel/polyfill';
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import './plugins/vuetify';
import './plugins/vee-validate';
import App from './App.vue';
import router from './router';
import store from '@/store';
import './registerServiceWorker';
import 'vuetify/dist/vuetify.min.css';
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import * as VueGoogleMaps from './components/gmap-vue/main.js'
import VueLazyload from 'vue-lazyload'
import DatetimePicker from 'vuetify-datetime-picker'
import VueGtag from "vue-gtag";
import VuetifyConfirm from 'vuetify-confirm'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta';
import VuetifyGoogleAutocomplete from "./views/VuetifyGoogleAutocomplete.vue";

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAezqLCHupd02eAP3lm43eZXKWfr-RoY1k',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    region: 'PH',
  },
  installComponents: true
})
Vue.use(VuetifyGoogleAutocomplete)

Vue.use(VueMeta);

Vue.config.productionTip = false;
Vue.use(VueGtag, {
  config: { id: "G-JLR1KZRFT1" }
});

Vue.use(VueGtag, {
  config: { id: "AW-368517415" }
});

Vue.use(VueCookies)

Vue.use(DatetimePicker)

Vue.use(VueLazyload)
Vue.use(VuetifyConfirm, { vuetify })

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
