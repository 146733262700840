import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';
import Contact from './views/Contact.vue'
Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [

        {
          path: "payment_failed",
          name: "payment_failed",
          component: () =>
            import(/* webpackChunkName: "payment_failed" */ "./views/PaymentFailed.vue"),
        },
        {
          path: "schedule_confirmed",
          name: "schedule_confirmed",
          component: () =>
            import(/* webpackChunkName: "schedule_confirmed" */ "./views/ScheduleConfirmed.vue"),
        },
        {
          path: "schedule_set",
          name: "schedule_set",
          component: () =>
            import(/* webpackChunkName: "schedule_set" */ "./views/ScheduleSet.vue"),
        },
        {
          path: "not_activated",
          name: "not_activated",
          component: () =>
            import(/* webpackChunkName: "not_activated" */ "./views/NotActivated.vue"),
        },
        {
          path: "not_feasible",
          name: "not_feasible",
          component: () =>
            import(/* webpackChunkName: "not_feasible" */ "./views/NotFeasible.vue"),
        },
        {
          path: "contact_review",
          name: "contact_review",
          component: () =>
            import(/* webpackChunkName: "contact_review" */ "./views/ContactReview.vue"),
        },
        {
          path: "feasibility",
          name: "feasibility",
          component: () =>
            import(/* webpackChunkName: "feasibility" */ "./views/Feasibility.vue"),
        },
        {
          path: "opt-out",
          name: "opt-out",
          component: () =>
            import(/* webpackChunkName: "opt-out" */ "./views/OptOut.vue"),
        },
        {
          path: "id_verify_foreign",
          name: "id_verify_foreign",
          component: () =>
            import(/* webpackChunkName: "id_verify" */ "./views/IDVerifyForeign.vue"),
        },
        {
          path: "id_verify_local",
          name: "id_verify_local",
          component: () =>
            import(/* webpackChunkName: "id_verify" */ "./views/IDVerifyLocal.vue"),
        },
        {
          path: "id_verify",
          name: "id_verify",
          component: () =>
            import(/* webpackChunkName: "id_verify" */ "./views/IDVerify.vue"),
        }, {
          path: "test_apply",
          component: () =>
            import(/* webpackChunkName: "test_apply" */ "./views/TestApply.vue"),
        },
        {
          path: "apply",
          component: () =>
            import(/* webpackChunkName: "apply" */ "./views/FeasibilityApply.vue"),
        },
        {
          path: "social_apply",
          component: () =>
            import(/* webpackChunkName: "social_apply" */ "./views/Apply.vue"),
        },
        {
          path: "contact",
          name: 'contact',
          component: Contact,
          props: true
        },
        {
          path: "plans",
          name: 'plans',
          component: () =>
            import(/* webpackChunkName: "plans" */ "./views/Plans.vue"),
          props: true,
        },
        {
          path: 'logout',
          component: () => import(/* webpackChunkName: "logout" */ './views/Login.vue'),
          props: true,
        },
        {
          path: 'token-login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "token-login" */ './views/Login.vue'),
        },
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'),
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                    /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/sales_report',
                  component: () => import(
                    /* webpackChunkName: "main-sales-report" */ './views/main/admin/SalesReport.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'),
                },
                {
                  path: 'users/create-admin',
                  name: 'main-admin-users-create-admin',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create-admin" */ './views/main/admin/CreateAdminUser.vue'),
                },
                {
                  path: "qb",
                  name: "qb",
                  component: () =>
                    import(/* webpackChunkName: "qb" */ "./views/main/admin/QB.vue"),
                },
                {
                  path: 'users/buildings',
                  component: () => import(
                    /* webpackChunkName: "main-buildings" */ './views/main/admin/Buildings.vue'),
                },
                {
                  path: 'users/all_receipts',
                  component: () => import(
                    /* webpackChunkName: "main-all-receipts" */ './views/main/admin/ReceiptReport.vue'),
                },
                {
                  path: 'users/MAR',
                  component: () => import(
                    /* webpackChunkName: "main-MAR" */ './views/main/admin/MAR.vue'),
                },
                {
                  path: 'users/PromoCosts',
                  component: () => import(
                    /* webpackChunkName: "main-PromoReport" */ './views/main/admin/PromoReport.vue'),
                },
                {
                  path: 'users/botlog_report',
                  component: () => import(
                    /* webpackChunkName: "main-botlog-report" */ './views/main/admin/BotlogReport.vue'),
                },
              ],

            },
          ],
        },
      ],

    },
    {
      path: '/*', redirect: '/',
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});
