






















































































































































































































import { Component, Vue } from "vue-property-decorator";
import { readIsLoggedIn, readUserProfile } from "@/store/main/getters";

import moment from "moment-timezone";
import Progress from "./Progress.vue";
import _ from "lodash";

@Component({
  components: { Progress },
  props: {
    progress: {
      type: String,
      default: null,
    },
    no_progress: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Header extends Vue {
  items = [
    {
      title: "Home",
      link: "https://www.horizonfiber.ph/",
    },
    {
      title: "Pricing",
      link: "https://www.horizonfiber.ph/pricing/",
    },
    {
      title: "News & Promos",
      link: "https://www.horizonfiber.ph/news-and-promos/",
    },
    {
      title: "About Us",
      link: "https://www.horizonfiber.ph/about-us/",
    },
    {
      title: "Contact Us",
      link: "https://www.horizonfiber.ph/contact-us/",
    },
    {
      title: "FAQ",
      link: "https://www.horizonfiber.ph/faq/",
    },
  ];
  loginItems = [
    {
      title: "Apply Now",
      link: "/apply",
    },
    {
      title: "Member's Login",
      link: "/",
    },
  ];
  logoutItems = [
    {
      title: "Logout",
      link: "/logout",
    },
  ];
  public menu = null;
  public menu2 = null;

  hasProgress() {
    const progress = _.get(this.userProfile, "progress", 1);
    const progress_status_code = _.get(
      this.userProfile,
      "progress_status_code",
      null
    );
    const is_superuser = _.get(this.userProfile, "is_superuser", false);
    return (
      readIsLoggedIn(this.$store) &&
      !!progress &&
      !is_superuser &&
      !!progress_status_code &&
      progress_status_code !== "70"
    );
  }

  isLoggedIn() {
    return readIsLoggedIn(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
  redirectUser(link) {
    window.location.href = link;
  }

  formatDate(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY HH:mm");
    }
    return "";
  }
}
